import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SocialAdFeatureService } from 'src/app/features/ad/social-ad-feature.service';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import {
	AdFormatEnum,
	IAdWithPlacementsVM,
	IAssignedCreativeVM,
} from 'src/app/presentation/view-models';
import { ConnectionStatusEnum } from 'src/app/core/models/account/integration/connection-status.enum';
import { ValidationMessage } from 'src/app/core/models/shared/validation-message';
import { ValidationMessageTypeEnum } from 'src/app/core/models/shared/validation-message-type.enum';
import { SocialAccountFeatureService } from 'src/app/features/account/social-account-feature.service';

@Injectable({
	providedIn: 'root',
})
export class PublishValidationService {
	constructor(
		private socialAdFeatureService: SocialAdFeatureService,
		private socialAccountFeatureService: SocialAccountFeatureService,
		private textPipe: TextPipe,
	) {}

	public isSelectedAdsValidForPublish(
		allSelectedAdsIds: string[],
	): Observable<ValidationMessage[]> {
		if (!allSelectedAdsIds.length) {
			return of([]);
		}

		const validateConnection$ =
			this.validateIfSomeOfAdsAreNotConnectedToAdSet(allSelectedAdsIds);
		const validateIntegration$ =
			this.validateIfSomeOfAdsAreConnectedToInvalidIntegration(
				allSelectedAdsIds,
			);
		const validateAssignedPlacements$ =
			this.validateIfSomeOfAdsPlacementsHaveUnAssignedCreative(
				allSelectedAdsIds,
			);
		const validateEmptyContent$ =
			this.validateIfSomeOfAdsHaveEmptyContent(allSelectedAdsIds);

		return combineLatest([
			validateConnection$,
			validateIntegration$,
			validateAssignedPlacements$,
			validateEmptyContent$,
		]).pipe(map((messages) => messages.filter((message) => !!message)));
	}

	private validateIfSomeOfAdsAreNotConnectedToAdSet(
		allSelectedAdsIds: string[],
	): Observable<ValidationMessage> {
		const adGroupNotConnectedMessage = this.textPipe.transform(
			'Shared.NO_CONNECTED_AD_SETS',
		);
		const validationMessage: ValidationMessage = {
			type: ValidationMessageTypeEnum.notConnectedWarning,
			message: adGroupNotConnectedMessage,
		};

		return this.socialAdFeatureService.ad.loadByIds(allSelectedAdsIds).pipe(
			switchMap((ads) =>
				combineLatest(
					ads.map((ad) =>
						this.socialAdFeatureService.adGroup.loadById(
							ad.adGroupId,
						),
					),
				),
			),
			map((adGroups) =>
				adGroups.every((adGroup) => !!adGroup.connection),
			),
			map((isConnected) => (isConnected ? null : validationMessage)),
		);
	}

	private validateIfSomeOfAdsAreConnectedToInvalidIntegration(
		allSelectedAdsIds: string[],
	): Observable<ValidationMessage> {
		const adGroupInvalidIntegrationMessage = this.textPipe.transform(
			'Shared.NO_VALID_INTEGRATION',
		);
		const validationMessage: ValidationMessage = {
			type: ValidationMessageTypeEnum.notValidIntegrationWarning,
			message: adGroupInvalidIntegrationMessage,
		};

		return this.socialAdFeatureService.ad.loadByIds(allSelectedAdsIds).pipe(
			switchMap((ads) =>
				combineLatest(
					ads.map((ad) =>
						this.socialAdFeatureService.adGroup.loadById(
							ad.adGroupId,
						),
					),
				),
			),
			switchMap((adGroups) =>
				combineLatest(
					adGroups.map((adGroup) =>
						adGroup?.connection
							? this.socialAccountFeatureService.integration.loadConnectionStatus(
									adGroup.connection.integrationId,
								)
							: of({}),
					),
				),
			),
			map((connectionStatuses: any) =>
				connectionStatuses.some(
					(connectionStatus) =>
						connectionStatus.connectionStatus ===
							ConnectionStatusEnum.Invalid ||
						connectionStatus.connectionStatus ===
							ConnectionStatusEnum.Expired,
				),
			),
			map((isInvalid) => (isInvalid ? validationMessage : null)),
		);
	}

	private validateIfSomeOfAdsPlacementsHaveUnAssignedCreative(
		allSelectedAdsIds: string[],
	): Observable<ValidationMessage> {
		const someOfPlacmentsHaveNoAssignedCreativesMessage =
			this.textPipe.transform(
				'Shared.SOME_PLACEMENTS_HAVE_NO_ASSIGNED_CREATIVES',
			);
		const validationMessage: ValidationMessage = {
			type: ValidationMessageTypeEnum.notAssignedCreativeWarning,
			message: someOfPlacmentsHaveNoAssignedCreativesMessage,
		};

		return this.socialAdFeatureService.ad
			.loadByIdsWithPlacements(allSelectedAdsIds)
			.pipe(
				switchMap((selectedAds) =>
					this.isAllSelectedAdsHaveAssignedcreatives(selectedAds),
				),
				map((isPlacementsAssigned) =>
					isPlacementsAssigned ? null : validationMessage,
				),
			);
	}

	private validateIfSomeOfAdsHaveEmptyContent(
		allSelectedAdsIds: string[],
	): Observable<ValidationMessage> {
		const noAdContentMessage = this.textPipe.transform(
			'Shared._SOME_ADS_HAVE_NO_CONTENT',
		);
		const validationMessage: ValidationMessage = {
			type: ValidationMessageTypeEnum.emptyAdContentWarning,
			message: noAdContentMessage,
		};

		return this.socialAdFeatureService.ad.loadByIds(allSelectedAdsIds).pipe(
			map((ads) => ads.every((ad) => !!ad.hasContent)),
			map((hasContent) => (hasContent ? null : validationMessage)),
		);
	}

	private isAllSelectedAdsHaveAssignedcreatives(
		ads: IAdWithPlacementsVM[],
	): Observable<boolean> {
		return of(
			ads.every((ad) => !!this.isAllAdPlacementsAssignedToCreatives(ad)),
		);
	}

	private isAllAdPlacementsAssignedToCreatives(
		ad: IAdWithPlacementsVM,
	): boolean {
		let isAssignedCreativeForSingleAndCarousel: boolean;

		switch (ad.adFormatId) {
			case AdFormatEnum.singleImageAndVideo:
			case AdFormatEnum.singleImage:
			case AdFormatEnum.singleVideo:
				isAssignedCreativeForSingleAndCarousel = ad?.placements?.every(
					(placement) => !!placement.creative,
				);
				break;
			case AdFormatEnum.carousel:
				isAssignedCreativeForSingleAndCarousel = ad.placements.every(
					(placement) =>
						!!placement.cards.length &&
						placement.cards.every((card) => !!card.creative),
				);
				break;
		}

		return isAssignedCreativeForSingleAndCarousel;
	}
}

export interface ICombinedAdCreativeWithPlacements {
	supportedPlacements: string[];
	adCreativeSet: string;
	adCreatives: IAssignedCreativeVM[];
}
