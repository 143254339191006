import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppAuthGuard } from './presentation/auth-guards/app-auth-guard';
import { appResolver } from './presentation/app-resolvers/app.resolver';
import { NotFoundPageComponent } from './presentation/pages/not-found/not-found-page.component';

export const routes: Routes = [
	{
		path: ':accountSlug/:brandSlug',
		canActivate: [AppAuthGuard],
		resolve: { init: appResolver },
		children: [
			{
				path: 'campaign/:campaignId',
				loadChildren: () =>
					import('./presentation/scm-page.module').then(
						(m) => m.SocialCampaignPageModule,
					),
			},
			{
				path: 'creativeset/:creativeSetId',
				loadChildren: () =>
					import(
						'./presentation/pages/creative-set/creative-set-navigation-page.module'
					).then((m) => m.CreativeSetNavigationPageModule),
			},
		],
	},
	{ path: '**', component: NotFoundPageComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
