import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { Router } from '@angular/router';

export enum NotFoundReason {
	campaign = 'campaign',
	adgroup = 'adgroup',
	ad = 'ad',
	default = 'default',
}

@Injectable()
export class ErrorRequestInterceptor implements HttpInterceptor {
	constructor(
		private readonly authHttpInterceptor: AuthHttpInterceptor,
		private readonly router: Router,
	) {}

	public intercept(
		request: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		if (window['Cypress'] || (window as any).env?.isTest) {
			return next.handle(request);
		}

		return this.authHttpInterceptor.intercept(request, next).pipe(
			tap({
				next: () => {},
				error: (err) => {
					if (err instanceof HttpErrorResponse) {
						if (err.status === 404) {
							const reason = this.notFound(
								request.url,
								request.method,
							);
							if (reason) {
								this.router.navigate(['/not-found'], {
									queryParams: { reason: reason },
								});
							}
						}
					}
				},
			}),
		);
	}

	private notFound(url: string, method: string): NotFoundReason {
		const campaignRegex =
			/^https:\/\/(?:[a-zA-Z0-9-]+(?:-sandbox)?\.[a-zA-Z0-9.-]+|\[?localhost]?):?(?:\d{1,5})?\/api\/social-campaigns/;
		const adgroupRegex =
			/^https:\/\/(?:[a-zA-Z0-9-]+(?:-sandbox)?\.[a-zA-Z0-9.-]+|\[?localhost]?):?(?:\d{1,5})?\/api\/ad-groups\/[a-fA-F0-9-]+$/;
		const adgroupRegexSecond =
			/^https:\/\/(?:[a-z.-]+)(?:\/api\/ads\/[a-f0-9-]+)\?adGroupId=[a-z0-9-]+$/;
		const adRegex =
			/^https:\/\/(?:[a-zA-Z0-9-]+(?:-sandbox)?\.[a-zA-Z0-9.-]+|\[?localhost]?):?(?:\d{1,5})?\/api\/ads/;

		if (campaignRegex.test(url) && method.toUpperCase() === 'GET') {
			return NotFoundReason.campaign;
		} else if (
			(adgroupRegex.test(url) && method.toUpperCase() === 'GET') ||
			(adgroupRegexSecond.test(url) && method.toUpperCase() === 'GET')
		) {
			return NotFoundReason.adgroup;
		} else if (adRegex.test(url) && method.toUpperCase() === 'GET') {
			return NotFoundReason.ad;
		} else {
			return NotFoundReason.default;
		}
	}
}
