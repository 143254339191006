import { Injectable } from '@angular/core';
import {
	IUIDialogConfig,
	UIConfirmDialogConfig,
	UIConfirmDialogResult,
	UIConfirmDialogService,
	UIDialogRef,
	UIDialogService,
} from '@bannerflow/ui';
import { PromptDialogComponent } from '../components/prompt-dialog/prompt-dialog.component';
import { TextPipe } from '../pipes/text.pipe';

export enum ModalType {
	discardUnsavedChanges = 'discardUnsavedChangesConfig',
	exitUnsavedChanges = 'exitUnsavedChangesConfig',
	adLanguageWarning = 'adLanguageWarningConfig',
	adLanguageWarningCarousel = 'adLanguageCarouselWarningConfig',
	disconnectAdSetFB = 'disconnectAdSetConfigFB',
	disconnectAdSetTT = 'disconnectAdSetConfigTT',
	disconnectAdSetIN = 'disconnectAdSetConfigIN',
	missingFacebookPage = 'missingFacebookPageConfig',
	deleteSingleAd = 'deleteSingleAdConfig',
	deleteSinglePublishedAd = 'deleteSinglePublishedAdConfig',
	deleteSelectedAds = 'deleteSelectedAdsConfig',
	deleteSelectedPublishedAds = 'deleteSelectedPublishedAdsConfig',
	deleteSingleAdGroup = 'deleteSingleAdGroupConfig',
	deleteSingleAdGroupWithSomePublishedAd = 'deleteSingleAdGroupWithSomePublishedAdConfig',
	deleteCampaign = 'deleteCampaignConfig',
	deleteSingleAdSchedule = 'deleteSingleAdScheduleConfig',
	revertCustomization = 'revertCustomizationConfig',
	bulkEditingAdsFailed = 'bulkEditingAdsFailedConfig',
}
@Injectable({
	providedIn: 'root',
})
export class SocialConfirmDialogService {
	private discardUnsavedChangesHeaderText = this.textPipe.transform(
		'SocialConfirmDialogService.UNSAVED_CHANGES',
	);
	private discardUnsavedChangesCancelText = this.textPipe.transform(
		'SocialConfirmDialogService.CANCEL_TEXT',
	);
	private discardUnsavedChangesConfirmText = this.textPipe.transform(
		'SocialConfirmDialogService.CANCEL_CONFIRM_TEXT',
	);
	private discardUnsavedChangesDiscardText = this.textPipe.transform(
		'SocialConfirmDialogService.CANCEL_DISCARD_TEXT',
	);
	private exitUnsavedChangesText = this.textPipe.transform(
		'SocialConfirmDialogService.EXIT_TEXT',
	);
	private exitUnsavedChangesConfirmText = this.textPipe.transform(
		'SocialConfirmDialogService.EXIT_CONFIRM_TEXT',
	);
	private exitUnsavedChangesDiscardText = this.textPipe.transform(
		'SocialConfirmDialogService.EXIT_DISCARD_TEXT',
	);
	private adLanguageWarningHeaderText = this.textPipe.transform(
		'SocialConfirmDialogService.AD_LANGUAGE_CHANGE',
	);
	private adLanguageWarningText = this.textPipe.transform(
		'SocialConfirmDialogService.AD_LANGUAGE_WARNING_TEXT',
	);
	private adLanguageWarningCarouselText = this.textPipe.transform(
		'SocialConfirmDialogService.AD_LANGUAGE_WARNING_TEXT_CAROUSEL',
	);
	private adLanguageWarningCarouselConfirmText = this.textPipe.transform(
		'SocialConfirmDialogService.UNASSIGN_CREATIVE_SETS',
	);
	private adLanguageWarningConfirmText = this.textPipe.transform(
		'SocialConfirmDialogService.UNASSIGN_CREATIVE_SET',
	);
	private adLanguageWarningCancelText = this.textPipe.transform(
		'SocialConfirmDialogService.DONT_CHANGE_LANGUAGE',
	);
	private disconnectAdSetTextFB = this.textPipe.transform(
		'SocialConfirmDialogService.ARE_YOU_SURE_YOU_WANT_TO_DISCONNECT_FACEBOOK',
	);
	private disconnectAdSetTextTT = this.textPipe.transform(
		'SocialConfirmDialogService.ARE_YOU_SURE_YOU_WANT_TO_DISCONNECT_TIKTOK',
	);
	private disconnectAdSetTextIN = this.textPipe.transform(
		'SocialConfirmDialogService.ARE_YOU_SURE_YOU_WANT_TO_DISCONNECT_LINKEDIN',
	);
	private disconnectAdSetConfirmText = this.textPipe.transform(
		'SocialConfirmDialogService.YES',
	);
	private disconnectAdSetCancelText = this.textPipe.transform(
		'SocialConfirmDialogService.NO',
	);
	private disconnectAdSetHeaderTextFB = this.textPipe.transform(
		'Shared.DISCONNECT_FACEBOOK',
	);
	private disconnectAdSetHeaderTextTT = this.textPipe.transform(
		'Shared.DISCONNECT_TIKTOK',
	);
	private disconnectAdSetHeaderTextIN = this.textPipe.transform(
		'Shared.DISCONNECT_LINKEDIN',
	);
	private missingFacebookPageText = this.textPipe.transform(
		'SocialConfirmDialogService.MISSING_FACEBOOK_PAGE_TEXT',
	);
	private missingFacebookPageConfirmText = this.textPipe.transform(
		'SocialConfirmDialogService.TRY_AGAIN',
	);
	private missingFacebookPageCancelText = this.textPipe.transform(
		'SocialConfirmDialogService.CANCEL',
	);
	private missingFacebookPageHeaderText = this.textPipe.transform(
		'SocialConfirmDialogService.PUBLISH_ATTEMPT_FAILED',
	);
	private deleteSingleAdHeaderText = this.textPipe.transform(
		'SocialConfirmDialogService.DELETE_AD',
	);
	private deleteSingleAdText = this.textPipe.transform(
		'SocialConfirmDialogService.DELETE_SINGLE_UNPUBLISHED_AD_TEXT',
	);
	private deleteSingleAdConfirmText = this.textPipe.transform(
		'SocialConfirmDialogService.DELETE',
	);
	private deleteSingleAdCancelText = this.textPipe.transform(
		'SocialConfirmDialogService.CANCEL',
	);
	private deleteSinglePublishedAdText = this.textPipe.transform(
		'SocialConfirmDialogService.DELETE_SINGLE_PUBLISHED_AD_TEXT',
	);
	private deleteSelectedAdsHeaderText = this.textPipe.transform(
		'SocialConfirmDialogService.DELETE_SELECTED_ADS',
	);
	private deleteSelectedAdsText = this.textPipe.transform(
		'SocialConfirmDialogService.DELETE_SELECTED_UNPUBLISHED_ADS_TEXT',
	);
	private deleteSelectedAdsConfirmText = this.textPipe.transform(
		'SocialConfirmDialogService.DELETE',
	);
	private deleteSelectedAdsCancelText = this.textPipe.transform(
		'SocialConfirmDialogService.CANCEL',
	);
	private deleteSelectedPublishedAdsText = this.textPipe.transform(
		'SocialConfirmDialogService.DELETE_SELECTED_PUBLISHED_ADS_TEXT',
	);
	private deleteSingleAdGroupHeaderText = this.textPipe.transform(
		'SocialConfirmDialogService.DELETE_ADGROUP',
	);
	private deleteSingleAdGroupText = this.textPipe.transform(
		'SocialConfirmDialogService.DELETE_SINGLE_UNPUBLISHED_ADGROUP_TEXT',
	);
	private deleteSingleAdGroupConfirmText = this.textPipe.transform(
		'SocialConfirmDialogService.DELETE',
	);
	private deleteSingleAdGroupCancelText = this.textPipe.transform(
		'SocialConfirmDialogService.CANCEL',
	);
	private deleteCampaignText = this.textPipe.transform(
		'SocialConfirmDialogService.DELETE_UNPUBLISHED_CAMPAIGN_TEXT',
	);
	private deleteCampaignHeaderText = this.textPipe.transform(
		'SocialConfirmDialogService.DELETE_CAMPAIGN_HEADER_TEXT',
	);
	private deleteCampaignConfirmText = this.textPipe.transform(
		'SocialConfirmDialogService.DELETE',
	);
	private deleteCampaignCancelText = this.textPipe.transform(
		'SocialConfirmDialogService.CANCEL',
	);

	private deleteSingleAdScheduleHeaderText = this.textPipe.transform(
		'SocialConfirmDialogService.DELETE_SCHEDULE',
	);
	private deleteSingleAdScheduleText = this.textPipe.transform(
		'SocialConfirmDialogService.DELETE_SCHEDULE_TEXT',
	);
	private deleteSingleAdScheduleConfirmText = this.textPipe.transform(
		'SocialConfirmDialogService.DELETE',
	);
	private deleteSingleAdScheduleCancelText = this.textPipe.transform(
		'SocialConfirmDialogService.CANCEL',
	);

	private publishAdsConnectAdSetWarningHeaderText = this.textPipe.transform(
		'SocialConfirmDialogService.CANNOT_BE_PUBLISHED',
	);
	private publishAdsConnectAdSetWarningText = this.textPipe.transform(
		'SocialConfirmDialogService.CONNECT_AD_SETS',
	);
	private publishAdsInvalidIntegrationWarningText = this.textPipe.transform(
		'SocialConfirmDialogService.CONNECT_VALID_INTEGRATION',
	);
	private publishAdsNotAssignedAndNotConnectedOrInvalidIntegrationWarningText =
		this.textPipe.transform(
			'SocialConfirmDialogService.ASSIGN_CREATIVES_AND_CONNECT_VALID_INTEGRATION',
		);
	private publishAdsAssignCreativeWarningText = this.textPipe.transform(
		'SocialConfirmDialogService.ASSIGN_CREATIVES',
	);
	private publishAdsMultipleWarningsText = this.textPipe.transform(
		'SocialConfirmDialogService.ASSIGN_CREATIVES_AND_CONNECT_AD_SETS',
	);

	private deleteSingleAdGroupWithSomePublishedAdHeaderText =
		this.textPipe.transform('SocialConfirmDialogService.DELETE_ADGROUP');
	private deleteSingleAdGroupWithSomePublishedAdText =
		this.textPipe.transform(
			'SocialConfirmDialogService.DELETE_SINGLE_ADGROUP_WITH_PUBLISHED_AD_TEXT',
		);

	private revertCustomizationHeaderText = this.textPipe.transform(
		'SocialConfirmDialogService.REVERT_CUSTOMIZATION_HEADER_TEXT',
	);
	private revertCustomizationText = this.textPipe.transform(
		'SocialConfirmDialogService.REVERT_CUSTOMIZATION_TEXT',
	);
	private revertCustomizationConfirmText = this.textPipe.transform(
		'SocialConfirmDialogService.REVERT_CUSTOMIZATION_CONFIRM_TEXT',
	);
	private revertCustomizationCancelText = this.textPipe.transform(
		'SocialConfirmDialogService.CANCEL',
	);

	private bulkEditFailed = this.textPipe.transform(
		'SocialConfirmDialogService.BULK_EDIT_FAILED',
	);

	private bulkEditNotSaved = this.textPipe.transform(
		'SocialConfirmDialogService.BULK_EDIT_NOT_SAVED',
	);

	private discardUnsavedChangesConfig: UIConfirmDialogConfig = {
		headerText: this.discardUnsavedChangesHeaderText,
		text: this.discardUnsavedChangesCancelText,
		confirmText: this.discardUnsavedChangesConfirmText,
		discardText: this.discardUnsavedChangesDiscardText,
		theme: 'default',
	};

	private exitUnsavedChangesConfig: UIConfirmDialogConfig = {
		headerText: this.discardUnsavedChangesHeaderText,
		text: this.exitUnsavedChangesText,
		confirmText: this.exitUnsavedChangesConfirmText,
		discardText: this.exitUnsavedChangesDiscardText,
		theme: 'default',
	};

	private adLanguageWarningConfig: UIConfirmDialogConfig = {
		headerText: this.adLanguageWarningHeaderText,
		text: this.adLanguageWarningText,
		confirmText: this.adLanguageWarningConfirmText,
		discardText: this.adLanguageWarningCancelText,
		theme: 'default',
		maxWidth: '540px',
	};
	private adLanguageCarouselWarningConfig: UIConfirmDialogConfig = {
		headerText: this.adLanguageWarningHeaderText,
		text: this.adLanguageWarningCarouselText,
		confirmText: this.adLanguageWarningCarouselConfirmText,
		discardText: this.adLanguageWarningCancelText,
		theme: 'default',
		maxWidth: '540px',
	};

	private disconnectAdSetConfigFB: UIConfirmDialogConfig = {
		headerText: this.disconnectAdSetHeaderTextFB,
		text: this.disconnectAdSetTextFB,
		confirmText: this.disconnectAdSetConfirmText,
		discardText: this.disconnectAdSetCancelText,
		theme: 'default',
	};

	private disconnectAdSetConfigTT: UIConfirmDialogConfig = {
		headerText: this.disconnectAdSetHeaderTextTT,
		text: this.disconnectAdSetTextTT,
		confirmText: this.disconnectAdSetConfirmText,
		discardText: this.disconnectAdSetCancelText,
		theme: 'default',
	};

	private disconnectAdSetConfigIN: UIConfirmDialogConfig = {
		headerText: this.disconnectAdSetHeaderTextIN,
		text: this.disconnectAdSetTextIN,
		confirmText: this.disconnectAdSetConfirmText,
		discardText: this.disconnectAdSetCancelText,
		theme: 'default',
	};

	private missingFacebookPageConfig: UIConfirmDialogConfig = {
		headerText: this.missingFacebookPageHeaderText,
		text: this.missingFacebookPageText,
		confirmText: this.missingFacebookPageConfirmText,
		discardText: this.missingFacebookPageCancelText,
		theme: 'default',
	};

	private deleteSingleAdConfig: UIConfirmDialogConfig = {
		headerText: this.deleteSingleAdHeaderText,
		text: this.deleteSingleAdText,
		confirmText: this.deleteSingleAdConfirmText,
		discardText: this.deleteSingleAdCancelText,
		theme: 'default',
	};

	private deleteSinglePublishedAdConfig: UIConfirmDialogConfig = {
		headerText: this.deleteSingleAdHeaderText,
		text: this.deleteSinglePublishedAdText,
		confirmText: this.deleteSingleAdConfirmText,
		discardText: this.deleteSingleAdCancelText,
		theme: 'default',
		width: '620px',
	};

	private deleteSelectedAdsConfig: UIConfirmDialogConfig = {
		headerText: this.deleteSelectedAdsHeaderText,
		text: this.deleteSelectedAdsText,
		confirmText: this.deleteSelectedAdsConfirmText,
		discardText: this.deleteSelectedAdsCancelText,
		theme: 'default',
	};

	private deleteSelectedPublishedAdsConfig: UIConfirmDialogConfig = {
		headerText: this.deleteSelectedAdsHeaderText,
		text: this.deleteSelectedPublishedAdsText,
		confirmText: this.deleteSelectedAdsConfirmText,
		discardText: this.deleteSelectedAdsCancelText,
		theme: 'default',
		width: '680px',
	};

	private deleteSingleAdGroupConfig: UIConfirmDialogConfig = {
		headerText: this.deleteSingleAdGroupHeaderText,
		text: this.deleteSingleAdGroupText,
		confirmText: this.deleteSingleAdGroupConfirmText,
		discardText: this.deleteSingleAdGroupCancelText,
		theme: 'default',
	};

	private deleteCampaignConfig: UIConfirmDialogConfig = {
		headerText: this.deleteCampaignHeaderText,
		text: this.deleteCampaignText,
		confirmText: this.deleteCampaignConfirmText,
		discardText: this.deleteCampaignCancelText,
		theme: 'default',
	};

	private deleteSingleAdScheduleConfig: UIConfirmDialogConfig = {
		headerText: this.deleteSingleAdScheduleHeaderText,
		text: this.deleteSingleAdScheduleText,
		confirmText: this.deleteSingleAdScheduleConfirmText,
		discardText: this.deleteSingleAdScheduleCancelText,
		theme: 'default',
	};

	private revertCustomizationConfig: UIConfirmDialogConfig = {
		headerText: this.revertCustomizationHeaderText,
		text: this.revertCustomizationText,
		confirmText: this.revertCustomizationConfirmText,
		discardText: this.revertCustomizationCancelText,
		theme: 'default',
	};

	private deleteSingleAdGroupWithSomePublishedAdConfig: UIConfirmDialogConfig =
		{
			headerText: this.deleteSingleAdGroupWithSomePublishedAdHeaderText,
			text: this.deleteSingleAdGroupWithSomePublishedAdText,
			confirmText: this.deleteSingleAdGroupConfirmText,
			discardText: this.deleteSingleAdGroupCancelText,
			theme: 'default',
			width: '680px',
		};

	private bulkEditingAdsFailedConfig: UIConfirmDialogConfig = {
		headerText: this.bulkEditFailed,
		text: this.bulkEditNotSaved,
		theme: 'default',
		maxWidth: '600px',
		backdropClickClose: false,
	};

	constructor(
		private confirmDialogService: UIConfirmDialogService,
		private textPipe: TextPipe,
		private dialogService: UIDialogService,
	) {}

	public async showDialog(
		prompt: ModalType = ModalType.discardUnsavedChanges,
	): Promise<UIConfirmDialogResult> {
		return this.confirmDialogService.confirm(this[prompt]);
	}

	public async showDialogWithMessages(
		messages: string[],
		prompt: ModalType = ModalType.discardUnsavedChanges,
	): Promise<UIConfirmDialogResult> {
		const staticConfig: IUIDialogConfig = this[prompt];
		const configWithMessages: IUIDialogConfig = {
			...staticConfig,
			data: { messages },
		};

		// Create & open a new dialog
		const dialogRef: UIDialogRef = this.dialogService.openComponent(
			PromptDialogComponent,
			configWithMessages,
		);

		// Wait for view to initialize
		await dialogRef.afterViewInit;

		// Wait for user to confirm or not
		const confirm: Promise<UIConfirmDialogResult> =
			await dialogRef.subComponentRef.instance.initiate();

		// Close after confirmation
		dialogRef.close();

		// return the result
		return confirm;
	}
}
