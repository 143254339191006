import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LOCALIZATION_ENTITY } from 'src/app/state/app.state';
import { ILocalization } from 'src/app/core/models/account/localization.model';
import { AppCoreServices } from 'src/app/core/app-core-service';

@Injectable()
export class LocalizationDataService extends DefaultDataService<ILocalization> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(LOCALIZATION_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery(params: {
		accountSlug: string;
		brandSlug: string;
	}): Observable<ILocalization[]> {
		return this.appCoreServices.accountAccessApiService.getLocalizations(
			params['accountSlug'],
			params['brandSlug'],
		);
	}
}
