import { Injectable } from '@angular/core';
import {
	IUIDialogConfig,
	UIConfirmDialogConfig,
	UIConfirmDialogResult,
	UIDialogRef,
	UIDialogService,
} from '@bannerflow/ui';
import { ValidationMessage } from 'src/app/core/models/shared/validation-message';
import { ValidationMessageTypeEnum } from 'src/app/core/models/shared/validation-message-type.enum';
import { PromptDialogComponent } from 'src/app/presentation/pages/shared/components/prompt-dialog/prompt-dialog.component';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';

@Injectable({
	providedIn: 'root',
})
export class PublishSummaryPromptService {
	private PUBLISH_ADS_CONNECT_AD_SET_WARNING_HEADER: string;
	private EMPTY_CONTENT_WARNING_MESSAGE: string;
	private ASSIGN_CREATIVES_PROMPT: string;
	private CONNECT_AD_GROUPS_PROMPT: string;
	private ASSIGN_CREATIVES_AND_CONNECT_PROMPT: string;
	private CONNECT_AD_GROUPS_AD_SET_PROMPT: string;
	private ASSIGN_CREATIVES_TO_PLACEMENTS_PROMPT: string;
	private CONNECT_AD_GROUPS_TO_VALID_INTEGRATION_PROMPT: string;

	constructor(
		private readonly dialogService: UIDialogService,
		private readonly textPipe: TextPipe,
	) {
		this.messagesTransformers();
	}

	public async showPublishValidationPrompt(
		validationMessages: ValidationMessage[],
	): Promise<UIConfirmDialogResult> {
		const prompt: UIConfirmDialogConfig = {
			headerText: this.PUBLISH_ADS_CONNECT_AD_SET_WARNING_HEADER,
			text: this.validationPrompt(validationMessages),
			theme: 'default',
			maxWidth: '600px',
			backdropClickClose: false,
		};

		const staticConfig: IUIDialogConfig = prompt;
		const messages = validationMessages.map(
			(validationMessage) => validationMessage.message,
		);

		const configWithMessages: IUIDialogConfig = {
			...staticConfig,
			data: { messages },
		};

		const dialogRef: UIDialogRef = this.dialogService.openComponent(
			PromptDialogComponent,
			configWithMessages,
		);

		await dialogRef.afterViewInit;

		const confirm: Promise<UIConfirmDialogResult> =
			await dialogRef.subComponentRef.instance.initiate();

		dialogRef.close();

		return confirm;
	}

	public validationPrompt(messages: ValidationMessage[]): string {
		const hasEmptyContentWarning = this.hasEmptyContentWarning(messages);
		const logicalMessages = this.filterLogicalMessages(messages);
		let prompt = '';

		if (logicalMessages.length > 1) {
			prompt = this.handleCombinedLogicalMessages(logicalMessages);
		} else if (logicalMessages.length === 1) {
			prompt = this.handleSingleLogicalMessage(logicalMessages[0].type);
		}

		return hasEmptyContentWarning
			? `${this.EMPTY_CONTENT_WARNING_MESSAGE} ${prompt}`
			: prompt;
	}

	private filterLogicalMessages(
		messages: ValidationMessage[],
	): ValidationMessage[] {
		const logicalMessages = messages.filter(
			(message) =>
				message.type !==
				ValidationMessageTypeEnum.emptyAdContentWarning,
		);

		return logicalMessages;
	}

	private handleSingleLogicalMessage(
		messageType: ValidationMessageTypeEnum,
	): string {
		let prompt = '';
		switch (messageType) {
			case ValidationMessageTypeEnum.notConnectedWarning:
				prompt = this.CONNECT_AD_GROUPS_AD_SET_PROMPT;
				break;
			case ValidationMessageTypeEnum.notAssignedCreativeWarning:
				prompt = this.ASSIGN_CREATIVES_TO_PLACEMENTS_PROMPT;
				break;
			case ValidationMessageTypeEnum.notValidIntegrationWarning:
				prompt = this.CONNECT_AD_GROUPS_TO_VALID_INTEGRATION_PROMPT;
				break;
		}

		return prompt;
	}

	public handleCombinedLogicalMessages(
		messages: ValidationMessage[],
	): string {
		let prompt = '';

		const messageTypes = messages.map((message) => message.type);
		if (
			messageTypes.every(
				(type) =>
					type ===
						ValidationMessageTypeEnum.notAssignedCreativeWarning ||
					type === ValidationMessageTypeEnum.notConnectedWarning,
			)
		) {
			prompt = this.ASSIGN_CREATIVES_PROMPT;
		} else if (
			messageTypes.every(
				(type) =>
					type === ValidationMessageTypeEnum.notConnectedWarning ||
					type ===
						ValidationMessageTypeEnum.notValidIntegrationWarning,
			)
		) {
			prompt = this.CONNECT_AD_GROUPS_PROMPT;
		} else {
			prompt = this.ASSIGN_CREATIVES_AND_CONNECT_PROMPT;
		}

		return prompt;
	}

	private hasEmptyContentWarning(messages: ValidationMessage[]): boolean {
		return messages.some(
			(message) =>
				message.type ===
				ValidationMessageTypeEnum.emptyAdContentWarning,
		);
	}

	private messagesTransformers(): void {
		this.PUBLISH_ADS_CONNECT_AD_SET_WARNING_HEADER =
			this.textPipe.transform(
				'SocialConfirmDialogService.CANNOT_BE_PUBLISHED',
			);
		this.EMPTY_CONTENT_WARNING_MESSAGE = this.textPipe.transform(
			'SocialConfirmDialogService.FILL_REQUIRED_FIELDS',
		);
		this.ASSIGN_CREATIVES_PROMPT = this.textPipe.transform(
			'SocialConfirmDialogService.ASSIGN_CREATIVES_AND_CONNECT_AD_SETS',
		);
		this.CONNECT_AD_GROUPS_PROMPT = this.textPipe.transform(
			'SocialConfirmDialogService.CONNECT_VALID_INTEGRATION',
		);
		this.ASSIGN_CREATIVES_AND_CONNECT_PROMPT = this.textPipe.transform(
			'SocialConfirmDialogService.ASSIGN_CREATIVES_AND_CONNECT_VALID_INTEGRATION',
		);
		this.CONNECT_AD_GROUPS_AD_SET_PROMPT = this.textPipe.transform(
			'SocialConfirmDialogService.CONNECT_AD_SETS',
		);
		this.ASSIGN_CREATIVES_TO_PLACEMENTS_PROMPT = this.textPipe.transform(
			'SocialConfirmDialogService.ASSIGN_CREATIVES',
		);
		this.CONNECT_AD_GROUPS_TO_VALID_INTEGRATION_PROMPT =
			this.textPipe.transform(
				'SocialConfirmDialogService.CONNECT_VALID_INTEGRATION',
			);
	}
}
