import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ConfigService } from 'src/app/core/config/config.service';
import { Observable } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';
import { ApiService } from '../internal/api.service';
import { IAdGroup } from '../../models/ad/ad-group/ad-group.model';
import { ICampaign } from '../../models/campaign/campaign.model';
import { IAdLog } from '../../models/ad/ad/ad-log.model';
import { TiktokCampaignApiService } from './tiktok/tt-campaign-api.service';
import { AdApiService } from './services/ad-api.service';
import { AdPlacementTemplateApiService } from './services/ad-placement-template-api';
import { AdGroupApiService } from './services/ad-group-api.service';
import { FacebookCampaignApiService } from './facebook/fb-campaign-api.service';
import { CreaitveSetApiService } from './services/creative-set/creative-set-api.service';
import { CustomizationApiService } from './services/customization-api.service';
import { PlacementOptionsApiService } from './services/placement-options-api';
import { CtaApiService } from './services/cta-api';
import { LinkedInCampaignApiService } from './linkedin/in-campaign-api.service';
import { AdFormatApiService } from './services/ad-format-api.service';

@Injectable({
	providedIn: 'root',
})
export class SocialCampaignApiService {
	public facebook: FacebookCampaignApiService;
	public tiktok: TiktokCampaignApiService;
	public linkedin: LinkedInCampaignApiService;

	public ad: AdApiService;
	public adPlacementTemplate: AdPlacementTemplateApiService;
	public adGroup: AdGroupApiService;
	public placement: PlacementOptionsApiService;
	public customization: CustomizationApiService;
	public creativeSet: CreaitveSetApiService;
	public cta: CtaApiService;
	public adFormat: AdFormatApiService;

	public baseUrl = `${ConfigService?.config?.SCS_URL}/api`;

	constructor(
		private http: HttpClient,
		private injector: Injector,
	) {
		this.facebook = this.injector.get(FacebookCampaignApiService);
		this.tiktok = this.injector.get(TiktokCampaignApiService);
		this.linkedin = this.injector.get(LinkedInCampaignApiService);

		this.ad = this.injector.get(AdApiService);
		this.adPlacementTemplate = this.injector.get(
			AdPlacementTemplateApiService,
		);
		this.adGroup = this.injector.get(AdGroupApiService);
		this.placement = this.injector.get(PlacementOptionsApiService);
		this.customization = this.injector.get(CustomizationApiService);
		this.creativeSet = this.injector.get(CreaitveSetApiService);
		this.cta = this.injector.get(CtaApiService);
		this.adFormat = this.injector.get(AdFormatApiService);
	}

	/**
	 * Gets single social campaign
	 * @param id
	 */

	public getCampaign(id: string): Observable<ICampaign> {
		const endpoint = `social-campaigns/${id}`;
		const url = `${this.baseUrl}/${endpoint}`;

		return this.http.get<ICampaign>(url).pipe(
			map((campaign) => campaign),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Create a single social campaign
	 * @param name
	 */
	public createCampaign(campaign: ICampaign): Observable<ICampaign> {
		const endpoint = `social-campaigns`;
		const url = `${this.baseUrl}/${endpoint}`;
		const name = campaign.name;

		return this.http
			.post<ICampaign>(url, { name }, { observe: 'response' })
			.pipe(
				flatMap((res: HttpResponse<any>) => {
					const location = res.headers.get('location');
					return this.http.get<ICampaign>(location);
				}),
				map((campaignResponse) => campaignResponse),
				catchError(ApiService.handleError),
			);
	}

	/**
	 * Rename a single social campaign
	 * @param id
	 * @param name
	 */
	public renameCampaign(id: string, name: string): Observable<ICampaign> {
		const endpoint = `social-campaigns/${id}`;
		const url = `${this.baseUrl}/${endpoint}`;

		const body: Partial<ICampaign> = {
			name,
		};

		return this.http.patch<ICampaign>(url, body).pipe(
			map((campaign) => campaign),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Delete a single social campaign
	 * @param id
	 */
	public deleteCampaign(id: string): Observable<string> {
		const endpoint = `social-campaigns/${id}`;
		const url = `${this.baseUrl}/${endpoint}`;

		return this.http
			.delete<string>(url)
			.pipe(catchError(ApiService.handleError));
	}

	/**
	 * Get Ad Groups
	 * @param campaignId
	 */
	public getAdGroups(campaignId: string): Observable<IAdGroup[]> {
		const endpoint = `ad-groups`;
		const queryParameters = `?socialCampaignId=${campaignId}`;
		const url = `${this.baseUrl}/${endpoint}${queryParameters}`;

		return this.http.get<IAdGroup[]>(url).pipe(
			map((adGroups) => adGroups),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Rename a single adGroup
	 * @param id
	 * @param name
	 */
	public renameAdGroup(id: string, name: string): Observable<IAdGroup> {
		const endpoint = `ad-groups/${id}`;
		const url = `${this.baseUrl}/${endpoint}`;

		const body = [
			{
				op: 'replace',
				path: '/name',
				value: name,
			},
		];

		return this.http.patch<any>(url, body).pipe(
			map((adGroup) => adGroup),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Get Ad by campaign id
	 * @param campaignId
	 */
	public getAdsByCampaignId(
		campaignId: string,
		includeDeleted: boolean = true,
	): Observable<IAdLog[]> {
		const url = `${this.baseUrl}/social-campaigns/${campaignId}/ads?includeDeleted=${includeDeleted}`;

		return this.http.get<IAdLog[]>(url).pipe(
			map((adLog) => adLog),
			catchError(ApiService.handleError),
		);
	}
}
