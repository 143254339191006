import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { TextPipe } from '../shared/pipes/text.pipe';
import { UIModule } from '@bannerflow/ui';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'not-found-page',
	templateUrl: './not-found-page.component.html',
	styleUrls: ['./not-found-page.component.scss'],
	standalone: true,
	imports: [TextPipe, UIModule, CommonModule],
})
export class NotFoundPageComponent {
	public message = 'Page Not Found';
	public isLoading = true;

	constructor(
		private readonly route: ActivatedRoute,
		private readonly cdRef: ChangeDetectorRef,
	) {}

	public ngOnInit(): void {
		setTimeout(() => {
			this.route.queryParams.subscribe((params) => {
				if (params['reason']) {
					switch (params['reason']) {
						case 'ad':
							this.message = 'Ad not found';
							break;
						case 'adgroup':
							this.message = 'Ad group not found';
							break;
						case 'campaign':
							this.message = 'Campaign not found';
							break;
						default:
							this.message = 'Page Not Found';
					}
				}
			});

			this.isLoading = false;
			this.cdRef.detectChanges();
		}, 500);
	}
}
